import React, { useState } from 'react';
import './App.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

function App() {
  const [openFolder, setOpenFolder] = useState<string | null>(null);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const handleFolderClick = (folderName: string) => {
    setOpenFolder(openFolder === folderName ? null : folderName);
  };

  const handleItemClick = (item: string) => {
    setSelectedItem(item);
  };

  return (
    <div className="app-container">
      <Header />
      <div className="folder-container">
        <div className="folder-header">
          <div className="section-number">// ABOUT</div>
          <div className="section-equation">
            <span>WE</span> + <span>AR</span> + <span>WE</span> = <span>∞</span>
          </div>
        </div>
        
        <div className="folder-content">
          <div className="content-left">
            <div className="about-text">
              In a digital realm where creativity meets technology, the "we AR we" collection was born—an assembly of 3333 unique NFTs, each carrying its own story, its own vibe, and a piece of a larger narrative. Built within the Arweave ecosystem, this collection reflects the innovation and permanence that Arweave represents.
            </div>
          </div>
          <div className="content-right">
            <div className="macos-folder-section">
              <div className="folder-grid">
                <div className="folder-item">
                  <div 
                    className={`folder-icon ${openFolder === 'distribution' ? 'open' : ''}`} 
                    onClick={() => handleFolderClick('distribution')}
                  >
                    <img src="/folders.png" alt="Distribution" />
                    <div className="folder-label">Distribution</div>
                  </div>

                  {openFolder === 'distribution' && (
                    <>
                      <div className="folder-overlay" onClick={() => setOpenFolder(null)}></div>
                      <div className="folder-window">
                        <div className="folder-window-header">
                          <div className="window-controls">
                            <span className="control close" onClick={(e) => { e.stopPropagation(); setOpenFolder(null); }}></span>
                            <span className="control minimize"></span>
                            <span className="control maximize"></span>
                          </div>
                          <div className="window-title">Distribution</div>
                        </div>
                        <div className="folder-window-content">
                          <div 
                            className="file-item pdf"
                            onClick={() => {
                              const link = document.createElement('a');
                              link.href = '/Distribution.pdf';
                              link.download = 'Distribution.pdf';
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            }}
                          >
                            <div className="file-icon">
                              <svg viewBox="0 0 24 24" className="pdf-icon">
                                <path d="M7 3H17L21 7V21H3V3H7ZM7 5V7H5V19H19V8L16 5H7Z" fill="currentColor"/>
                                <path d="M15 13H9V11H15V13Z" fill="currentColor"/>
                                <path d="M15 17H9V15H15V17Z" fill="currentColor"/>
                              </svg>
                            </div>
                            <span className="file-name">Distribution.pdf</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="folder-item">
                  <div 
                    className={`folder-icon ${openFolder === 'about' ? 'open' : ''}`} 
                    onClick={() => handleFolderClick('about')}
                  >
                    <img src="/folders.png" alt="About Team" />
                    <div className="folder-label">About Team</div>
                  </div>

                  {openFolder === 'about' && (
                    <>
                      <div className="folder-overlay" onClick={() => setOpenFolder(null)}></div>
                      <div className="folder-window">
                        <div className="folder-window-header">
                          <div className="window-controls">
                            <span className="control close" onClick={(e) => { e.stopPropagation(); setOpenFolder(null); }}></span>
                            <span className="control minimize"></span>
                            <span className="control maximize"></span>
                          </div>
                          <div className="window-title">About Us</div>
                        </div>
                        <div className="folder-window-content">
                          <div className="file-item txt">
                            <div className="file-icon">
                              <svg viewBox="0 0 24 24" className="txt-icon">
                                <path d="M14 2H6C4.89 2 4 2.89 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2ZM18 20H6V4H13V9H18V20Z" fill="currentColor"/>
                                <path d="M9 13H15V15H9V13Z" fill="currentColor"/>
                                <path d="M9 17H15V19H9V17Z" fill="currentColor"/>
                              </svg>
                            </div>
                            <span className="file-name">About.txt</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="decorative-pattern"></div>
            <div className="graph-pattern">
              <img src="/Genesis-nft-nobg.png" alt="Genesis NFT 1" className="showcase-nft left-nft" />
              <img src="/Genesis-nft-2.png" alt="Genesis NFT 2" className="showcase-nft right-nft" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
