import React from 'react';
import './Header.css';

const Header: React.FC = () => {
  return (
    <nav className="top-nav">
      <div className="nav-links">
        <a href="https://x.com/we_AR_we" target="_blank" rel="noopener noreferrer">X</a>
        <span className="disabled-link">Discord</span>
        <a href="https://ao.arweave.dev" target="_blank" rel="noopener noreferrer">AO</a>
      </div>
      <div className="bazar-link">
        <span className="coming-soon">SOON</span>
        <div className="bazar-container">
          <img src="/bazar-icon.png" alt="BazAr" className="bazar-icon" />
          <span className="bazar-text">Go to BazAr</span>
        </div>
      </div>
    </nav>
  );
};

export default Header;
