import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <div className="status-bar">
      <div className="status-text">
        <div className="status-text-item">
          <span>Chainin' vibezz</span>
          <span className="warning-icon">⚠</span>
          <span>buildin' storiez</span>
          <span className="warning-icon">⚠</span>
          <span>makin' frenz</span>
          <span className="warning-icon">⚠</span>
        </div>
        <div className="status-text-item">
          <span>Chainin' vibezz</span>
          <span className="warning-icon">⚠</span>
          <span>buildin' storiez</span>
          <span className="warning-icon">⚠</span>
          <span>makin' frenz</span>
          <span className="warning-icon">⚠</span>
        </div>
        <div className="status-text-item">
          <span>Chainin' vibezz</span>
          <span className="warning-icon">⚠</span>
          <span>buildin' storiez</span>
          <span className="warning-icon">⚠</span>
          <span>makin' frenz</span>
          <span className="warning-icon">⚠</span>
        </div>
        <div className="status-text-item">
          <span>Chainin' vibezz</span>
          <span className="warning-icon">⚠</span>
          <span>buildin' storiez</span>
          <span className="warning-icon">⚠</span>
          <span>makin' frenz</span>
          <span className="warning-icon">⚠</span>
        </div>
        <div className="status-text-item">
          <span>Chainin' vibezz</span>
          <span className="warning-icon">⚠</span>
          <span>buildin' storiez</span>
          <span className="warning-icon">⚠</span>
          <span>makin' frenz</span>
          <span className="warning-icon">⚠</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
